.maintenance {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-rows: 15% 70% 15%;
    height: 100vh;
    width: 100vw;
}

.maintenance * {
    align-self: center;
    justify-self: center;
}

.maintenance-gif {
    grid-column: 2;
    grid-row: 2;
    height: 80%;
    width: auto;
}

.halt {
    grid-column: 2;
    grid-row: 1;
}

.halt-reason {
    grid-column: 2;
    grid-row: 3;
}

.siren1 {
    grid-column: 1;
}

.siren1,
.siren2 {
    grid-row: 1;
    height: 100%;
    width: auto;
}

.siren2 {
    grid-column: 3;
}

.connor1 {
    grid-column: 1;
}

.connor1,
.connor2 {
    grid-row: 2;
    height: 85%;
    width: auto;
}

.connor2 {
    grid-column: 3;
}
