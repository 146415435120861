.close-button {
	background-color: #7f7f7f;
	border-radius: 20px;
	width: 25px;
	height: 25px;
	z-index: 1;
	opacity: 70%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.close-button span {
	display: block;
	position: absolute;
	height: 2px;
	width: 16px;
	background-color: black;
	border-radius: 9px;
	opacity: 1;
	transform: rotate(0deg);
	transition: 0.25s ease-in-out;
	transform-origin: center;
}

/*rotate 2 spans so they form an X centered in the button*/
.close-button span:nth-child(1) {
	transform: rotate(-45deg);
}

/*rotate 2 spans so they form an X centered in the button*/
.close-button span:nth-child(2) {
	transform: rotate(45deg);
}
