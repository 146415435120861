.resize-bar {
	padding: 30px 30px;
	width: 100%;
}

.resize-bar input {
	width: 100%;
	height: auto;
}

.resize-bar input:hover {
	cursor: pointer;
}
